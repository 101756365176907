<template>
  <v-row no-gutters ref="home" class="home">
    <v-col cols="12">
      <section id="banner-video" class="black">
        <div class="banner-image">
          <!-- <v-img :src="banner_image" width="100%" height="100%"></v-img> -->
          <div class="banner-container white--text">
            <div class="banner-heading">Happy Harbour</div>
            <h2><i>The Celebrations Cafe</i></h2>
            <div class="banner-description white--text">
              At Happy Harbour, we're dedicated to crafting unforgettable
              celebrations. Our affordable yet stylish venues are perfect for
              any occasion. With a focus on customer happiness and flexibility,
              we make your moments truly special. Join our community of
              celebrators today!
            </div>
            <div class="banner-action">
              <v-btn :to="{ name: 'BookOnline' }" color="primary" dark large>
                Book Now
              </v-btn>
            </div>
          </div>
        </div>
      </section>
    </v-col>

    <v-col cols="12">
      <section id="about-us" class="about-us text-left">
        <v-container>
          <v-card flat color="transparent" class="pa-5">
            <v-row no-gutters class="fill-height" justify="center">
              <v-col cols="12" lg="8" md="8" sm="8">
                <v-card
                  class="pa-lg-5 pa-md-5 pa-sm-3 text-center"
                  color="primary lighten-1"
                  flat
                >
                  <!-- <div class="about-us-title brown--text">ABOUT US</div> -->
                  <div class="about-us-heading secondary--text">About Us</div>
                  <!-- <div class="about-us-description lightgrey--text">
                    At Happy Harbour, we believe that every moment in life is
                    worth celebrating, and we're here to make those moments
                    truly unforgettable.
                  </div> -->
                  <div class="about-us-description lightgrey--text">
                    "At Happy Harbour, we're passionate about turning life's
                    moments into unforgettable celebrations. With a diverse
                    range of celebration spaces, exceptional service, and
                    affordable luxury starting from just ₹499, we're dedicated
                    to making your milestones shine. As a customer-centric
                    community of celebrators, we prioritize your happiness and
                    are ready to create tailor-made, resounding successes for
                    your special events. Join us in transforming your occasions
                    into cherished memories. Contact us today to book your next
                    unforgettable celebration at Happy Harbour."
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </section>
    </v-col>

    <!-- <v-col cols="12">
      <section id="how-it-works" class="how-it-works">
        <v-container>
          <v-card height="" color="primary" raised class=""> </v-card>
        </v-container>
      </section>
    </v-col> -->

    <!-- <v-col cols="12">
      <section id="our-space" class="our-space secondary">
        <v-container fluid>
          <v-row class="justify-center">
            <v-col
              cols="12"
              lg="4"
              offset-lg="2"
              offset-md="2"
              align-items="center"
            >
              <div class="text-left pr-lg-16 pr-md-16">
                <div class="our-space-title white--text">our space</div>
                <div class="our-space-heading primary--text">
                  Joyful experiences for you and your family
                </div>
                <div class="our-space-description white--text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
                  vero dolor asperiores veniam distinctio assumenda est!
                  Reprehenderit omnis minus dolor corrupti accusantium? Laborum
                  earum quam consequatur consectetur similique ad laboriosam.
                  <br />
                  <br />
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Natus mollitia saepe sit tempora molestiae magnam voluptates
                  provident cupiditate sunt impedit. Reiciendis nihil dolorum
                  nobis adipisci aliquam doloribus recusandae harum sit.
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="6" md="6">
              <v-row>
                <v-col cols="12" lg="6" md="6" sm="6">
                  <div>
                    <v-card
                      color="lightpink"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image1})`,
                      }"
                      min-height="350"
                    >
                    </v-card>
                    <v-card color="brown" raised tile>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Plan 1</div>

                            <div class="text-center">
                              <v-btn
                                small
                                color="primary"
                                class="brown--text"
                                :to="{
                                  name: 'BookOnline',
                                  params: { selectedPlanId: 2 },
                                }"
                              >
                                Book now at Rs299
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="6">
                  <div>
                    <v-card
                      color="lightpink"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image2})`,
                      }"
                      min-height="350"
                    ></v-card>
                    <v-card color="brown" hover tile>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Plan 2</div>

                            <div class="text-center">
                              <v-btn
                                small
                                color="primary"
                                class="brown--text"
                                :to="{
                                  name: 'BookOnline',
                                  params: { selectedPlanId: 1 },
                                }"
                              >
                                Book now at Rs399
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="6">
                  <div>
                    <v-card
                      color="lightpink"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image3})`,
                      }"
                      min-height="350"
                    >
                    </v-card>
                    <v-card color="brown" hover tile>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Plan 3</div>
                            <div class="text-center">
                              <v-btn
                                small
                                color="primary"
                                class="brown--text"
                                :to="{
                                  name: 'BookOnline',
                                  params: { selectedPlanId: 2 },
                                }"
                              >
                                Book Now at Rs499
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="6">
                  <div>
                    <v-card
                      color="lightpink"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image4})`,
                      }"
                      min-height="350"
                    >
                    </v-card>
                    <v-card color="brown" hover tile>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Plan 4</div>

                            <div class="text-center">
                              <v-btn
                                small
                                color="primary"
                                class="brown--text"
                                :to="{
                                  name: 'BookOnline',
                                  params: { selectedPlanId: 2 },
                                }"
                              >
                                Book Now at Rs799
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-col> -->

    <v-col cols="12">
      <section id="our-space" class="our-space secondary darken-5">
        <v-container fluid>
          <v-row class="justify-center">
            <v-col cols="12" lg="8" md="12">
              <v-row>
                <v-col cols="12">
                  <div class="our-space-heading primary--text">Our Space</div>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="6">
                  <div class="pa-3 pa-lg-8 pa-md-8 pa-sm-8">
                    <v-card
                      color="primary"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image1})`,
                      }"
                      min-height="450"
                      class="our-space-card"
                    >
                    </v-card>
                    <v-card color="transparent" tile flat>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Gala Red</div>
                            <div class="service-description">
                              Experience romantic and intimate celebrations in
                              our 6ft heart-shaped decor adorned with red
                              balloons, personalized neon lighting, and a cozy
                              sofa seating arrangement, offering 100% privacy
                              for couples and small groups of up to 4 people.
                              Enjoy the ambiance with wall lighting, a cake
                              stand, customized LED name board, and decorative
                              props, perfect for occasions like birthdays,
                              anniversaries, bridal showers, farewells, and
                              more.
                            </div>

                            <div class="service-action">
                              <router-link
                                class="primary--text"
                                :to="{ name: 'BookOnline' }"
                              >
                                Book now at Rs. 499
                              </router-link>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="6">
                  <div class="pa-3 pa-lg-8 pa-md-8 pa-sm-8">
                    <v-card
                      color="primary"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image2})`,
                      }"
                      min-height="450"
                      class="our-space-card"
                    ></v-card>
                    <v-card color="transparent" tile flat>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Mystic Orchid</div>
                            <div class="service-description">
                              Step into our intimate, premium space adorned
                              with a 6ft circle-shaped decor filled with pink and
                              purple balloons, customizable neon lights for your
                              special occasion, and a cozy setup for up to six
                              guests, complete with a cake stand, LED name
                              board, and high-quality entertainment options.
                            </div>

                            <div class="service-action">
                              <router-link
                                class="primary--text"
                                :to="{ name: 'BookOnline' }"
                              >
                                Book now at Rs. 699
                              </router-link>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="6">
                  <div class="pa-3 pa-lg-8 pa-md-8 pa-sm-8">
                    <v-card
                      color="primary"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image3})`,
                      }"
                      min-height="450"
                      class="our-space-card"
                    >
                    </v-card>
                    <v-card color="transparent" tile flat>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Golden Darbar</div>
                            <div class="service-description">
                              Elevate your group celebrations in our expansive
                              6ft rectangular setup adorned with black and gold
                              balloons. Enjoy premium amenities, including
                              personalized neon lighting, a fully equipped table
                              with props, spacious seating, and a 2K projector
                              with HD screen for unforgettable gatherings of up
                              to six people.
                            </div>

                            <div class="service-action">
                              <router-link
                                class="primary--text"
                                :to="{ name: 'BookOnline' }"
                              >
                                Book now at Rs. 899
                              </router-link>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="6">
                  <div class="pa-3 pa-lg-8 pa-md-8 pa-sm-8">
                    <v-card
                      color="primary"
                      raised
                      style="
                        background-size: cover;
                        background-position: center;
                      "
                      :style="{
                        'background-image': `url(${our_space.image4})`,
                      }"
                      min-height="450"
                      class="our-space-card"
                    >
                    </v-card>
                    <v-card color="transparent" tile flat>
                      <v-row no-gutters justify="center" class="fill-height">
                        <v-col cols="12" align-self="center" class="text-left">
                          <div class="service-content primary--text">
                            <div class="service-title">Sapphire Suite</div>
                            <div class="service-description">
                              Experience sophistication with our spacious room
                              featuring a 7ft rectangle decor filled with
                              elegant blue and white balloons, personalized neon
                              lighting, and top-notch amenities for groups of up
                              to eight.
                            </div>

                            <div class="service-action">
                              <router-link
                                class="primary--text"
                                :to="{ name: 'BookOnline' }"
                              >
                                Book now at Rs. 999
                              </router-link>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-col>

    <v-col cols="12">
      <section id="contact-us" class="contact-us">
        <v-row no-gutters justify="center">
          <v-col cols="12" lg="6" md="5" class="align-self-center">
            <v-card
              color="primary lighten-1"
              flat
              tile
              class="contact-us-cards"
            >
              <div class="contact-us-heading secondary--text">Visit Us</div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0757605737217!2d77.51648367484432!3d12.902849916382285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3ff2955bfacd%3A0x43bd13707f8ef6eb!2sHappy%20Harbour!5e0!3m2!1sen!2sin!4v1700634578455!5m2!1sen!2sin"
                width="90%"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" md="7">
            <v-card
              color="primary lighten-1"
              flat
              tile
              class="contact-us-cards secondary--text fill-height"
            >
              <div class="contact-us-heading">Contact Us</div>
              <contact-form></contact-form>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/ContactForm.vue";
export default {
  name: "HomeView",
  components: {
    ContactForm,
  },
  mounted() {
    if (this.$route.hash) {
      this.$vuetify.goTo(this.$route.hash, {
        duration: 750,
        offset: 0,
      });
    } else {
      this.$vuetify.goTo(0, {
        duration: 750,
        offset: 0,
      });
    }
  },
  watch: {
    "$route.hash": {
      handler: function (val) {
        if (val) {
          this.$vuetify.goTo(val, { duration: 1000, offset: 0 });
        } else {
          this.$vuetify.goTo(0, {
            duration: 1000,
            offset: 0,
          });
        }
      },
    },
  },
  data() {
    return {
      // bannerVideoMp4: require("@/assets/video/bannerVideo.mp4"),
      // bannerVideoWebm: require("@/assets/video/bannerVideo.webm"),
      // bannerVideo: require("@/assets/video/bannerVideo.gif"),
      // bannerVideoMobile: require("@/assets/video/bannerVideoMobile.gif"),
      banner_image: "https://happyharbourbucket.s3.ap-south-1.amazonaws.com/banner.jpg",
      our_space: {
        image1: "https://happyharbourbucket.s3.ap-south-1.amazonaws.com/plan/redGala.webp",
        image2: "https://happyharbourbucket.s3.ap-south-1.amazonaws.com/plan/mysticOrchid.webp",
        image3: "https://happyharbourbucket.s3.ap-south-1.amazonaws.com/plan/goldenDarbar.webp",
        image4: "https://happyharbourbucket.s3.ap-south-1.amazonaws.com/plan/sapphireSuite.webp",
      },
    };
  },
  methods: {
    scrollTo(id) {
      this.$router.push({ name: "HomeView", hash: id });
    },
  },
};
</script>
<style scoped>
.home .banner-video {
  position: relative;
  height: calc(100vh - 64px);
  width: 100%;
}

.home .banner-video .banner-text {
  color: white;
  font-size: 50px;
  font-weight: 700;
  position: absolute;
  top: 70%;
  left: 15%;
  transform: translate(-15%, -70%);
}

.home .banner-video .banner-button {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -95%);
}

.home .banner-video .banner-text h1 {
  line-height: 1;
}

.home .banner-video .banner-text h3 {
  margin-left: 10px;
}

.home .banner-image {
  position: relative;
  height: calc(100vh - 64px);
  width: 100%;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("../assets/banner.jpg");
  background-size: cover;
}

.home .banner-container {
  position: absolute;
  width: 30%;
  top: 70%;
  left: 80%;
  transform: translate(-80%, -70%);
  text-align: left;
  padding: 30px;
  border: 20px solid #ccdad1;
}
.home .banner-container .banner-heading {
  font-size: 50px;
  font-weight: 700;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.45);
}
.home .banner-container .banner-description {
  margin: 25px 0;
  font-size: 18px;
  line-height: 1.5;
  word-wrap: break-word;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  font-style: italic;
}

.home .about-us,
.home .our-space,
.home .testimonial,
.home .contact-us {
  padding: 100px 0;
}

.home .about-us .about-us-title,
.home .our-space .our-space-title,
.home .testimonial .testimonial-title,
.home .contact-us .contact-us-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 300;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
  margin-bottom: 22px;
}

.home .about-us .about-us-heading,
.home .our-space .our-space-heading,
.home .testimonial .testimonial-heading,
.home .contact-us .contact-us-heading {
  font-size: 48px;
  line-height: 52px;
  font-weight: 700;
  margin-bottom: 30px;
}

.home .our-space .our-space-card {
  border: 8px solid;
}
.home .our-space .our-space-description {
  font-size: 16px;
  line-height: 25px;
}
.home .about-us .about-us-description {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 500;
  font-style: italic;
}

.home .about-us .about-us-image {
  height: 250px;
  background-position: center;
}

.home .about-us .about-us-image.image3 {
  height: 508px;
}

.home .testimonial .review-image {
  background-position: top;
}

.home .testimonial .review-image,
.home .about-us .about-us-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.home .our-space .service-content {
  padding: 32px 0;
  text-align: left;
}

.home .our-space .service-title {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 700;
}
.home .our-space .service-description {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1.2px;
  font-style: italic;
  font-weight: 500;
  margin: 20px 0;
}
.home .our-space .service-action a {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1.2px;
  font-style: italic;
  font-weight: 500;
  margin: 20px 0;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 2px solid;
}
.home .contact-us-cards {
  padding: 60px 0;
}

@media only screen and (max-width: 1024px) {
  .home .banner-container {
    width: 45%;
  }
}

@media only screen and (max-width: 600px) {
  .home .banner-container {
    position: relative;
    width: 90%;
    margin: auto;
    top: 70%;
    left: 0%;
    transform: translate(0%, -70%);
    padding: 15px;
    border: 10px solid #ccdad1;
  }
  .home .banner-container .banner-heading {
    font-size: 40px;
  }
  .home .banner-container .banner-description {
    margin: 25px 0;
    font-size: 18px;
  }
  .home .about-us .about-us-image {
    height: 120px;
  }

  .home .about-us .about-us-image.image3 {
    height: 248px;
  }
}

@media only screen and (max-width: 960px) {
  .home .banner-video {
    height: calc(100vh - 56px);
  }

  .home .banner-video .banner-text {
    font-size: 30px;
  }

  .home .about-us,
  .home .our-space,
  .home .testimonial,
  .home .contact-us {
    padding: 50px 0;
  }

  .home .about-us .about-us-heading,
  .home .our-space .our-space-heading,
  .home .testimonial .testimonial-heading,
  .home .contact-us .contact-us-heading {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
  }

  .home .about-us .about-us-description {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
</style>
